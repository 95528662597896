@import "src/styles/variables.module";

.container {
  margin: 0 auto;
  padding: 0 $gutter;
  max-width: (1366px + 2 * $gutter);
}

.sm {
  margin: 0 auto;
  max-width: 400px;
}

p {
  color: #5c5c5c;
  font-size: 12px;
  text-align: center;
}
