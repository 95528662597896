@import "src/styles/mixins.module";
@import "src/styles/variables.module";

.list {
  border-radius: 10px;
  font-size: 12px;
}

.item:not(:first-child) {
  margin-top: 3px;
}

.article {
  @include flex(space-between);
}

.title {
  color: $brown;
  font-weight: normal;
  text-align: left;
}

.content {
  display: flex;
  overflow-x: hidden;
  color: $deepgray;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  @include desktop {
    max-width: 246px;
  }
  @include mobile {
    max-width: 166px;
  }
}
